import { FC } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Typography } from '@mui/material';

interface TimelyTimelineStep {
  title: string;
  desc?: string;
}

interface TimelyTimelineProps {
  steps: TimelyTimelineStep[];
  currentStep: number;
}

const TimelyTimeline: FC<TimelyTimelineProps> = ({ steps, currentStep }) => {
  const currentStepIndex = currentStep - 1;
  return (
    <Timeline position="right" sx={{ padding: 0 }}>
      {steps.map((step, key) => (
        <TimelineItem
          key={key}
          sx={{
            minHeight: 58,
            ':before': {
              content: 'none'
            },
            ':last-of-type': {
              minHeight: 20
            }
          }}
        >
          <TimelineSeparator>
            <TimelineDot
              color={currentStepIndex === key ? 'primary' : undefined}
              sx={{
                width: 16,
                height: 16,
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 600,
                margin: 0,
                boxShadow: 'none',
                backgroundColor: currentStepIndex >= key ? '#0069ff' : '#777777'
              }}
            >
              {key + 1}
            </TimelineDot>
            {key < steps.length - 1 && (
              <TimelineConnector
                sx={{
                  borderLeft:
                    currentStepIndex > key
                      ? '1px dashed #199CF3'
                      : '1px dashed #525863',
                  backgroundColor: 'white'
                }}
              />
            )}
          </TimelineSeparator>
          <TimelineContent
            sx={{
              paddingY: 0,
              paddingBottom: 1,
              mt: -0.5
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 600 }} gutterBottom>
              {step.title}
            </Typography>
            {step.desc ? (
              <Typography
                variant="body2"
                sx={{
                  color: '#708DA0',
                  wordBreak: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: '2'
                }}
              >
                {step.desc}
              </Typography>
            ) : null}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export { TimelyTimeline };
