export const PRODUCT_QUERY_FILLED = 'Product and Query Filled';
export const DATE_SELECTED = 'Date Selected';
export const SLOT_SELECTED = 'Slot Selected';
export const DATE_SLOT_CONFIRMED = 'Date Slot Confirmed';
export const CONTACT_DETAILS_FILLED = 'Contact Details Entered';
export const APPOINTMENT_CREATED = 'Appointment Created';
export const APPOINTMENT_CANCELLED = 'Appointment Cancelled';
export const APPOINTMENT_RESCHEDULED = 'Appointment Rescheduled';
export const PREV_APPOINTMENT_EXISTS = 'Duplicate Appointment';
export const SCHEDULING_ERROR = 'Error Scheduling Appointment';
export const EVENT_FETCH_FAILED = 'Fetching Event Failed';
export const APMT_FETCH_FAILED = 'Fetching Appointment Failed';
export const SLOTS_FETCH_FAILED = 'Fetching Slots Failed';
export const WHATSAPP_BTN_CLICKED = 'Whatsapp Btn Clicked';
export const TIMEZONE_CHANGED = 'Timezone Changed';
export const DESCRIPTION_LINK_CLICKED = 'Link In Description Clicked';
export const DESCRIPTION_WA_LINK_CLICKED = 'Description Whatsapp Link Clicked';
export const CLOSED_POP_UP = 'Closed Pop Up';
export const CLICKED_WHATSAPP_US_FROM_CLOSE_NUDGE =
  'Clicked WhatsApp Btn From Close Nudge';
export const CLICKED_CONTINUE_FROM_CLOSE_NUDGE =
  'Clicked Continue Btn From Close Nudge';
export const WHATSAPP_FORM_FILLED = 'WhatsApp Form Filled';
export const CLICKED_BACK_FROM_WA_FORM = 'Clicked Back From WhatsApp Form';

// GA4 Events
export const OPEN_WHATSAPP_FORM = 'open_whatsapp_form';
export const BOOKED_CALL = 'booked_call';
export const CANCEL_CALL_FORM = 'cancel_call_form';
export const FILLED_WHATSAPP_FORM = 'filled_wa_form';
