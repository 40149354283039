import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PageLoader } from '../components';
import { AuthProvider } from '../context/AuthProvider';
import { NoAuth, RequireAuth } from './Protected';
import { AppLayout, SchedulerLayout } from '../layout';
import { TimelyErrorBoundary } from '../components/error/TimelyErrorBoundary';
import Page404 from '../pages/404';
import config, { APP_STAGE_STAGING } from '../config';

const Login = lazy(() => import('../pages/auth/Login'));
const Logout = lazy(() => import('../pages/auth/Logout'));
const ResetPassword = lazy(() => import('../pages/auth/ResetPassword'));
const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
const Home = lazy(() => import('../pages/home/index'));
const ShowCase = lazy(() => import('../pages/showcase/index'));
const EventForm = lazy(() => import('../pages/event/EventForm'));
const EventSnapshot = lazy(() => import('../pages/event/Snapshot'));
const AppointmentForm = lazy(() => import('../pages/appointment/AppointmentForm'));
const CancelForm = lazy(() => import('../pages/appointment/CancelForm'));

const NoMatch = () => {
  return <Page404 />;
};

const AppRoutes = () => {
  return (
    <TimelyErrorBoundary>
      <Routes>
        <Route
          path="/login"
          element={
            <Suspense fallback={<PageLoader />}>
              <AuthProvider>
                <NoAuth>
                  <Login />
                </NoAuth>
              </AuthProvider>
            </Suspense>
          }
        />
        <Route
          path="/resetpassword/:token"
          element={
            <Suspense fallback={<PageLoader />}>
              <AuthProvider>
                <NoAuth>
                  <ResetPassword />
                </NoAuth>
              </AuthProvider>
            </Suspense>
          }
        />
        <Route
          path="/forgotpassword"
          element={
            <Suspense fallback={<PageLoader />}>
              <AuthProvider>
                <NoAuth>
                  <ForgotPassword />
                </NoAuth>
              </AuthProvider>
            </Suspense>
          }
        />

        <Route
          path="/event/*"
          element={
            <Suspense fallback={<PageLoader />}>
              <SchedulerLayout />
            </Suspense>
          }
        >
          <Route
            path=":eventSlug/book"
            element={
              <Suspense fallback={<PageLoader />}>
                <AppointmentForm />
              </Suspense>
            }
          />
          <Route
            path=":eventSlug/reschedule/:appmntId"
            element={
              <Suspense fallback={<PageLoader />}>
                <AppointmentForm />
              </Suspense>
            }
          />
          <Route
            path=":eventSlug/cancel/:appmntId"
            element={
              <Suspense fallback={<PageLoader />}>
                <CancelForm />
              </Suspense>
            }
          />
          <Route path="*" element={<NoMatch />} />
        </Route>

        <Route
          path="/admin/*"
          element={
            <Suspense fallback={<PageLoader />}>
              <AuthProvider>
                <RequireAuth>
                  <AppLayout />
                </RequireAuth>
              </AuthProvider>
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<PageLoader />}>
                <Home />
              </Suspense>
            }
          />

          <Route
            path="event/create"
            element={
              <Suspense fallback={<PageLoader />}>
                <EventForm />
              </Suspense>
            }
          />

          <Route
            path="event/:eventSlug/snapshot"
            element={
              <Suspense fallback={<PageLoader />}>
                <EventSnapshot />
              </Suspense>
            }
          />

          <Route
            path="event/:eventSlug/edit"
            element={
              <Suspense fallback={<PageLoader />}>
                <EventForm />
              </Suspense>
            }
          />

          {config.APP_STAGE !== APP_STAGE_STAGING && (
            <Route
              path="components"
              element={
                <Suspense fallback={<PageLoader />}>
                  <ShowCase />
                </Suspense>
              }
            />
          )}

          <Route path="logout" element={<Logout />} />

          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/" element={<Navigate to="/admin" />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </TimelyErrorBoundary>
  );
};

export default AppRoutes;
